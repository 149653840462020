import { useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { format, isSameDay } from "date-fns";
import { IconType } from "react-icons";
import { BiCalendar, BiDollarCircle } from "react-icons/bi";
import { MdOutlineWatchLater } from "react-icons/md";
import {
  Box,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  Text,
  VStack,
  Link,
} from "@chakra-ui/react";
import { ThemeCard } from "../../../components/ThemeCard";
import { VianikoEvent } from "../../../types/events";
import { Organization } from "../../../types/organization";
import { useCommunityTheme } from "../../../hooks/useCommunityTheme";
import { priceInDollars } from "../../../services/payments";
import {
  eventShowUrl,
  organizationShowUrl,
} from "../../../services/routes/urlBuilder";
import { getImageUrl } from "../../../services/images";
import { toZonedTime } from "date-fns-tz";
import { useEvent } from "../../../hooks/useEvent";

interface EventThemeCardProps {
  event: VianikoEvent;
  organization?: Organization;
}

export const EventThemeCard: React.FC<EventThemeCardProps> = ({
  event,
  organization,
}) => {
  const { primaryColor, complementaryColor } = useCommunityTheme(
    organization?.theme,
  );

  const { event: parentEvent } = useEvent(event.parent_event_id);

  const photoId = event.photo_ids?.[0] || organization?.photo_ids?.[0];

  const infoSections: {
    icon: IconType;
    title?: string;
    value?: string;
  }[] = useMemo(() => {
    const startAt = toZonedTime(new Date(event.start_at), event.iana_timezone);
    const endAt = toZonedTime(new Date(event.end_at), event.iana_timezone);

    const ticketTypeMinCents = Math.min(
      ...(event.ticket_types?.map((t) => {
        if (t.payment_type === "free") {
          return 0;
        } else if (t.payment_type === "slider") {
          return t.min_price_in_cents ?? 0;
        } else {
          return t.price_in_cents;
        }
      }) || []),
    );
    const ticketTypeMaxCents = Math.max(
      ...(event.ticket_types?.map((t) => {
        if (t.payment_type === "free") {
          return 0;
        } else if (t.payment_type === "slider") {
          return t.max_price_in_cents ?? 1000;
        } else {
          return t.price_in_cents;
        }
      }) || []),
    );

    return [
      {
        icon: BiCalendar,
        title: isSameDay(startAt, endAt)
          ? format(startAt, "MMMM d")
          : `${format(startAt, "MMM d")} - ${format(endAt, "MMM   d")}`,
        value: isSameDay(startAt, endAt)
          ? format(startAt, "EEEE")
          : `${format(startAt, "EEE")} - ${format(endAt, "EEE")}`,
      },
      {
        icon: MdOutlineWatchLater,
        title: format(startAt, "h:mm a"),
        value: "til " + format(endAt, "h:mm a"),
      },
      {
        icon: BiDollarCircle,
        title:
          event?.ticket_types?.length === 0
            ? ""
            : ticketTypeMaxCents === 0
              ? "Free"
              : ticketTypeMinCents === ticketTypeMaxCents
                ? priceInDollars(event.currency_iso_code, ticketTypeMinCents)
                : `${priceInDollars(
                    event.currency_iso_code,
                    ticketTypeMinCents,
                  )} - ${priceInDollars(
                    event.currency_iso_code,
                    ticketTypeMaxCents,
                  )}`,
        value: "",
      },
    ];
  }, [event]);

  return (
    <ThemeCard theme={organization?.theme} height="auto">
      <Flex flexDirection={"column"}>
        <Box minH="200px">
          <Flex
            flexDirection="column"
            gap="72px"
            zIndex={1}
            position="relative"
          >
            <Box maxWidth="320px" paddingTop={4} zIndex={1}>
              {parentEvent ? (
                <Link as={RouterLink} to={eventShowUrl(parentEvent.id)}>
                  <Text size="sm" color={complementaryColor} marginLeft={5}>
                    {parentEvent.name}
                  </Text>
                </Link>
              ) : organization ? (
                <Link as={RouterLink} to={organizationShowUrl(organization.id)}>
                  <Text size="sm" color={complementaryColor} marginLeft={5}>
                    {organization.name}
                  </Text>
                </Link>
              ) : null}
            </Box>
            <Box
              position="absolute"
              maxW="50%"
              right={0}
              top={0}
              bottom={0}
              borderRadius="24px"
            >
              {photoId ? (
                <Image
                  src={getImageUrl(photoId)}
                  width="100%"
                  height="100%"
                  fit="cover"
                  borderRadius="24px"
                />
              ) : null}
            </Box>

            <Heading
              zIndex={1}
              as="h1"
              size="xl"
              marginLeft={5}
              marginBottom={8}
              maxWidth="320px"
              color={primaryColor}
            >
              {event.name.split(" ").map((word, index) => (
                <Box as="span" key={index} backgroundColor={complementaryColor}>
                  {word + " "}
                </Box>
              ))}
            </Heading>
          </Flex>
        </Box>
        <Box minH="200px">
          <Flex>
            <Box width="50%">
              <VStack marginLeft={5} marginTop={4} gap={4} align="start">
                {infoSections.map(
                  (section) =>
                    section.title && (
                      <HStack align="start" key={section.title}>
                        <Icon as={section.icon} color={complementaryColor} />
                        <Box>
                          <Text size="md" color={complementaryColor}>
                            {section.title}
                          </Text>
                          <Text size="sm" color={`${complementaryColor}80`}>
                            {section.value}
                          </Text>
                        </Box>
                      </HStack>
                    ),
                )}
              </VStack>
            </Box>
            <Box width="50%"></Box>
          </Flex>
        </Box>
      </Flex>
    </ThemeCard>
  );
};
