import {
  Button,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { usePass } from "../hooks/usePass";
import { Panel } from "../../../components/Panel";
import { TEXT_SECONDARY_COLOR } from "../../../services/theme/colors";
import { priceInDollars } from "../../../services/payments";
import { passPaymentUrl } from "../../../services/routes/urlBuilder";
import { useNavigate } from "react-router-dom";

interface PurchasePassModalProps {
  passId: string;
  eventId: string;
  isOpen: boolean;
  onClose: () => void;
}

export const PurchasePassModal: React.FC<PurchasePassModalProps> = ({
  passId,
  eventId,
  isOpen,
  onClose,
}) => {
  const { pass } = usePass(passId);
  const navigate = useNavigate();

  const handleBuyPass = async () => {
    if (!pass) return;

    if (pass.is_subscription) {
      navigate(passPaymentUrl(passId, eventId));
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <HStack margin={4}>
          <Heading size="lg" as="h2">
            Buy a pass
          </Heading>
          <ModalCloseButton marginTop={2} />
        </HStack>

        {pass && (
          <ModalBody paddingTop={0}>
            <Panel>
              <HStack>
                <Heading as="h3" size="md">
                  {pass.name}
                </Heading>

                <Spacer />

                <Text size="md" color={TEXT_SECONDARY_COLOR}>
                  {priceInDollars("USD", pass.price_in_cents)}
                </Text>
              </HStack>

              <Text size="sm">Valid for {pass.num_months_active} months</Text>

              {pass.is_unlimited ? (
                <Text size="sm">Unlimited access</Text>
              ) : (
                <Text size="sm">Valid for {pass.num_credits} events</Text>
              )}

              {pass.description && (
                <Text size="md" color={TEXT_SECONDARY_COLOR} marginTop={4}>
                  {pass.description}
                </Text>
              )}
            </Panel>

            <Button
              variant="primary"
              onClick={handleBuyPass}
              size="lg"
              width="100%"
              marginTop={4}
              marginBottom={8}
            >
              Buy pass
            </Button>
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  );
};
