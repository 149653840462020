import { Box, Button, Heading, HStack, Portal, Spacer } from "@chakra-ui/react";
import { Pass } from "../../../../types/passes";
import { ButtonLink } from "../../../../components/ButtonLink";
import {
  eventPurchasePassUrl,
  eventShowUrl,
} from "../../../../services/routes/urlBuilder";
import { VianikoEvent } from "../../../../types/events";
import { useCurrentUserPass } from "../../../passes/hooks/useCurrentUserPass";
import { createTicketForPass } from "../../../../services/api/passes";
import { useNavigate } from "react-router-dom";
import { PassConfirmationModal } from "./PassConfirmationModal";
import { useState } from "react";

interface EventPassItemProps {
  pass: Pass;
  event: VianikoEvent;
}

export const EventPassItem: React.FC<EventPassItemProps> = ({
  pass,
  event,
}) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const { userPass, ticket } = useCurrentUserPass(pass.id, event.id);
  const navigate = useNavigate();

  const handleUsePass = async () => {
    setIsConfirmationModalOpen(true);
  };

  const handleConfirmUsePass = async () => {
    await createTicketForPass(pass.id, event.id);
    setIsConfirmationModalOpen(false);
    navigate(
      eventShowUrl(event.id, {
        welcome: true,
        invite: true,
      })
    );
  };

  console.log("userPass?.isValid", userPass?.isValid);

  return (
    <>
      {userPass && (
        <Portal>
          <PassConfirmationModal
            pass={pass}
            userPass={userPass}
            isOpen={isConfirmationModalOpen}
            onClose={() => setIsConfirmationModalOpen(false)}
            onConfirm={handleConfirmUsePass}
          />
        </Portal>
      )}

      <HStack key={pass.id}>
        <Box>
          <Heading size="sm" as="h4">
            {pass.name}
          </Heading>
        </Box>

        <Spacer />

        {!!ticket ? (
          <Button variant="primary" size="sm" isDisabled>
            Used
          </Button>
        ) : userPass?.isValid ? (
          <Button variant="primary" size="sm" onClick={handleUsePass}>
            Use
          </Button>
        ) : (
          <ButtonLink
            to={eventPurchasePassUrl(event.id, pass.id)}
            buttonProps={{ variant: "primary", size: "sm" }}
          >
            Buy
          </ButtonLink>
        )}
      </HStack>
    </>
  );
};
