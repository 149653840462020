import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  StyleProps,
} from "@chakra-ui/react";
import {
  Control,
  Controller,
  ControllerRenderProps,
  FieldError,
} from "react-hook-form";
import {
  isoAndDateToISO,
  isoAndTimeToISO,
  isoToDate,
  isoToReadableDate,
  isoToTime,
} from "../../services/timezone";
import { useEffect, useRef, useState } from "react";
import { BiCalendar, BiTime } from "react-icons/bi";

interface StyledDateTimeInputProps {
  name: string;
  control: Control<any>;
  defaultValue: string;
  timezone: string;
  onChange?: (value: string) => void;
  label?: string;
  error?: FieldError;
  rules?: any;
  dateOnly?: boolean;
  timeOnly?: boolean;
  styles?: StyleProps;
}

// data is of the format { date: string, time: string }
export const StyledDateTimeInput: React.FC<StyledDateTimeInputProps> = ({
  name,
  control,
  defaultValue,
  timezone,
  onChange,
  label,
  error,
  rules,
  dateOnly = false,
  timeOnly = false,
  styles: stylesProp,
}) => {
  const styles = {
    width: "100%",
    ...stylesProp,
  };

  const dateInputRef = useRef<HTMLInputElement>(null);
  const timeInputRef = useRef<HTMLInputElement>(null);
  const [dateInputIsFocused, setDateInputIsFocused] = useState(false);
  const [timeInputIsFocused, setTimeInputIsFocused] = useState(false);
  const [keyPressedRecently, setKeyPressedRecently] = useState(false);
  const [pointerPressedRecently, setPointerPressedRecently] = useState(false);

  useEffect(() => {
    if (dateInputRef.current && dateInputIsFocused && pointerPressedRecently) {
      dateInputRef.current.showPicker();
    }
    if (timeInputRef.current && timeInputIsFocused && pointerPressedRecently) {
      timeInputRef.current.showPicker();
    }
  }, [dateInputIsFocused, timeInputIsFocused, pointerPressedRecently]);

  const computeNewDate = (
    field: ControllerRenderProps,
    evt:
      | React.ChangeEvent<HTMLInputElement>
      | React.FocusEvent<HTMLInputElement, Element>,
  ): string | undefined => {
    if (!evt.target.value) return;
    return isoAndDateToISO(
      field.value || defaultValue,
      evt.target.value,
      timezone,
    );
  };

  const computeNewTime = (
    field: ControllerRenderProps,
    evt:
      | React.ChangeEvent<HTMLInputElement>
      | React.FocusEvent<HTMLInputElement, Element>,
  ): string | undefined => {
    if (!evt.target.value) return;
    return isoAndTimeToISO(
      field.value || defaultValue,
      evt.target.value,
      timezone,
    );
  };

  const emitOnChangeIfNotKeypressedRecently = (
    newDateTime: string | undefined,
  ) => {
    if (!keyPressedRecently) {
      if (onChange !== undefined && newDateTime !== undefined) {
        onChange(newDateTime);
      }
    }
  };

  const emitOnChange = (newDateTime: string | undefined) => {
    if (onChange !== undefined && newDateTime !== undefined) {
      onChange(newDateTime);
    }
  };

  useEffect(() => {
    if (!keyPressedRecently) return;
    const timeout = setTimeout(() => {
      setKeyPressedRecently(false);
    }, 40);
    return () => clearTimeout(timeout);
  }, [keyPressedRecently]);

  useEffect(() => {
    if (!pointerPressedRecently) return;
    const timeout = setTimeout(() => {
      setPointerPressedRecently(false);
    }, 40);
    return () => clearTimeout(timeout);
  }, [pointerPressedRecently]);

  return (
    <FormControl isInvalid={!!error} {...styles}>
      {label && (
        <FormLabel htmlFor={name} display="inline-block">
          {label}
        </FormLabel>
      )}
      <InputGroup>
        <Controller
          name={name}
          rules={rules}
          control={control}
          render={({ field }) => (
            <>
              <InputGroup
                variant="dateinput"
                visibility={timeOnly ? "hidden" : "visible"}
                width={timeOnly ? 0 : "auto"}
                maxWidth={192}
                marginRight={timeOnly ? 0 : 4}
              >
                <Input
                  ref={dateInputRef}
                  name={`${name}.date`}
                  type={dateInputIsFocused ? "date" : "text"}
                  variant="dateinput"
                  borderRight="none"
                  value={(() => {
                    if (dateInputIsFocused) {
                      return field.value
                        ? isoToDate(field.value, timezone)
                        : isoToDate(defaultValue, timezone);
                    } else {
                      return field.value
                        ? isoToReadableDate(field.value, timezone)
                        : isoToReadableDate(defaultValue, timezone);
                    }
                  })()}
                  onKeyDown={() => setKeyPressedRecently(true)}
                  onPointerDown={() => {
                    if (!dateInputIsFocused) {
                      // only set pointer pressed recently if this is not focussed yet
                      // because otherwise a second click will not enable the text edit mode
                      setPointerPressedRecently(true);
                    }
                  }}
                  onChange={(e) => {
                    const iso = computeNewDate(field, e);
                    emitOnChangeIfNotKeypressedRecently(iso);
                    field.onChange(iso);
                  }}
                  onFocus={() => {
                    setDateInputIsFocused(true);
                  }}
                  onBlur={(e) => {
                    const iso = computeNewDate(field, e);
                    emitOnChange(iso);
                    setDateInputIsFocused(false);
                  }}
                />

                <InputRightAddon
                  onClick={() => {
                    if (dateInputRef.current) {
                      dateInputRef.current.focus();
                      setPointerPressedRecently(true);
                    }
                  }}
                >
                  <BiCalendar />
                </InputRightAddon>
              </InputGroup>
              <InputGroup
                variant="dateinput"
                visibility={dateOnly ? "hidden" : "visible"}
                width={dateOnly ? 0 : "auto"}
                maxWidth={192}
              >
                <Input
                  ref={timeInputRef}
                  name={`${name}.time`}
                  type="time"
                  variant="dateinput"
                  width={48}
                  borderRight="none"
                  value={(() => {
                    return field.value
                      ? isoToTime(field.value, timezone)
                      : isoToTime(defaultValue, timezone);
                  })()}
                  onKeyDown={() => setKeyPressedRecently(true)}
                  onPointerDown={() => {
                    if (!timeInputIsFocused) {
                      // only set pointer pressed recently if this is not focussed yet
                      // because otherwise a second click will not enable the text edit mode
                      setPointerPressedRecently(true);
                    }
                  }}
                  onChange={(e) => {
                    const iso = computeNewTime(field, e);
                    emitOnChangeIfNotKeypressedRecently(iso);
                    field.onChange(iso);
                  }}
                  onFocus={() => {
                    setTimeInputIsFocused(true);
                  }}
                  onBlur={(e) => {
                    const iso = computeNewTime(field, e);
                    emitOnChange(iso);
                    setTimeInputIsFocused(false);
                  }}
                />
                <InputRightAddon
                  onClick={() => {
                    if (timeInputRef.current) {
                      timeInputRef.current.focus();
                      setPointerPressedRecently(true);
                    }
                  }}
                >
                  <BiTime />
                </InputRightAddon>
              </InputGroup>
            </>
          )}
        />
      </InputGroup>
      {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};
