import {
  Button,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { Panel } from "../../../../components/Panel";
import { Pass } from "../../../../types/passes";
import { TEXT_SECONDARY_COLOR } from "../../../../services/theme/colors";
import { UserPass } from "../../../../types/user_passes";
import { format } from "date-fns";

interface PassConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  pass: Pass;
  userPass: UserPass;
}

export const PassConfirmationModal: React.FC<PassConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  pass,
  userPass,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <HStack marginTop={2} marginBottom={4}>
            <Heading as="h3" size="lg">
              Use your pass
            </Heading>
            <Spacer />
            <ModalCloseButton marginTop={2} />
          </HStack>

          <Panel>
            <Heading size="md" as="h4">
              {pass.name}
            </Heading>

            <Text size="sm" marginTop={2}>
              {userPass.num_credits} remaining
              {userPass.expires_at && (
                <>
                  , use by{" "}
                  {format(new Date(userPass.expires_at), "MMM d, yyyy")}
                </>
              )}
            </Text>

            {pass.description && (
              <Text marginTop={4} color={TEXT_SECONDARY_COLOR}>
                {pass.description}
              </Text>
            )}
          </Panel>

          <Button
            onClick={onConfirm}
            variant="primary"
            size="lg"
            width="100%"
            marginY={4}
          >
            Use
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
