import { Control, RegisterOptions, useController } from "react-hook-form";
import { StyledInput } from "./StyledInput";

interface StyledControlledInputProps {
  name: string;
  control: Control<any>;
  label: string;
  type: string;
  rules?: RegisterOptions;
}

export const StyledControlledInput: React.FC<StyledControlledInputProps> = ({
  name,
  control,
  label,
  type,
  rules,
}) => {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  return (
    <StyledInput
      name={name}
      label={label}
      type={type}
      inputProps={{ value }}
      isRequired={!!rules?.required}
      onChange={onChange}
      error={error}
    />
  );
};
