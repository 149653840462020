import {
  addMinutes,
  differenceInCalendarDays,
  differenceInMinutes,
  format,
  isSameDay,
  parseISO,
  setHours,
  setMinutes,
  setSeconds,
} from "date-fns";
import { formatInTimeZone, fromZonedTime, toZonedTime } from "date-fns-tz";

export const isoToDate = (iso: string, timezone: string): string => {
  const date = toZonedTime(iso, timezone);
  return formatInTimeZone(date, timezone, "yyyy-MM-dd");
};

export const isoToReadableDate = (iso: string, timezone: string): string => {
  const date = toZonedTime(iso, timezone);
  return formatInTimeZone(date, timezone, "EEE, dd MMM");
};

export const isoToTime = (iso: string, timezone: string): string => {
  const date = formatInTimeZone(iso, timezone, "HH:mm");
  return date;
};

export const isoAndDateToISO = (
  iso: string,
  date: string,
  timezone: string
): string => {
  const parsedDate = fromZonedTime(date, timezone);
  const parsedTime = fromZonedTime(iso, timezone);

  const combinedTimestamp = setSeconds(
    setMinutes(
      setHours(parsedDate, parsedTime.getHours()),
      parsedTime.getMinutes()
    ),
    parsedTime.getSeconds()
  );

  const isoTimestamp = format(combinedTimestamp, "yyyy-MM-dd HH:mm:ssXXX");

  return isoTimestamp;
};

export const isoAndTimeToISO = (
  iso: string,
  time: string,
  timezone: string
): string => {
  const [hours, minutes] = time.split(":").map((n) => parseInt(n, 10));
  const parsedDate = parseISO(iso);

  const zonedTime = fromZonedTime(
    setHours(setMinutes(parsedDate, minutes), hours),
    timezone
  );

  const isoTimestamp = format(zonedTime, "yyyy-MM-dd HH:mm:ss.SSSXXX");
  return isoTimestamp;
};

export const isoDateTimeAddMinutes = (
  iso: string,
  timezone: string,
  amount: number
): string => {
  const parsedDate = fromZonedTime(iso, timezone);
  const newDate = addMinutes(parsedDate, amount);
  const isoTimestamp = format(newDate, "yyyy-MM-dd HH:mm:ssXXX");
  return isoTimestamp;
};

export const isSameDate = (
  firstISODate: string,
  secondISODate: string,
  timezone: string
): boolean => {
  const firstDate = fromZonedTime(firstISODate, timezone);
  const secondDate = fromZonedTime(secondISODate, timezone);
  return isSameDay(firstDate, secondDate);
};

export const deltaTimeInMinutes = (
  firstISODate: string,
  secondISODate: string,
  timezone: string
): number => {
  const firstDate = fromZonedTime(firstISODate, timezone);
  const secondDate = fromZonedTime(secondISODate, timezone);
  return differenceInMinutes(secondDate, firstDate);
};

export const daysDifferenceBetweenDates = (
  firstISODate: string,
  secondISODate: string,
  timezone: string
): number => {
  const firstDate = fromZonedTime(firstISODate, timezone);
  const secondDate = fromZonedTime(secondISODate, timezone);
  return differenceInCalendarDays(secondDate, firstDate);
};
