import { inputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = definePartsStyle({
  field: {
    borderRadius: "24px",
    _focus: {
      border: "2px dashed black",
    },
  },
});

const dateinput = definePartsStyle({
  field: {
    border: "2px solid",
    borderColor: "black",
    background: "white",
    borderRadius: "24px",
    _focus: {
      border: "2px dashed black",
    },
  },
  addon: {
    border: "2px solid",
    borderColor: "black",
    background: "white",
    borderRadius: "24px",
    color: "black",
    _focus: {
      border: "2px dashed black",
    },
    _active: {
      background: "gray.200",
    },
  },
});

export const inputTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {
    dateinput,
  },
});
