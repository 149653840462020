import { Heading, Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { usePass } from "../passes/hooks/usePass";
import { priceInDollars } from "../../services/payments";
import { CURRENCY_CODE_TO_CURRENCY } from "../../services/currencies";
import { Elements } from "@stripe/react-stripe-js";
import { useOrganization } from "../../providers/CurrentOrganizationProvider";
import { useStripeAccount } from "../../hooks/useStripeAccount";
import { SubscriptionPaymentForm } from "./SubscriptionPaymentForm";

export const SubscriptionPaymentPage: React.FC = () => {
  const { passId } = useParams();
  const { pass } = usePass(passId);
  const { organization } = useOrganization(pass?.organization_id);
  const { stripe } = useStripeAccount(organization?.id);
  if (!pass || !stripe) return null;

  return (
    <>
      <Heading size="md" marginY={2}>
        Payment for {pass.name}
      </Heading>
      <Text marginY={4}>
        {priceInDollars("USD", pass.price_in_cents)}{" "}
        {CURRENCY_CODE_TO_CURRENCY["USD"].code}
      </Text>
      <Elements
        stripe={stripe}
        options={{
          mode: "subscription",
          amount: pass.price_in_cents,
          currency: "usd",
        }}
      >
        <SubscriptionPaymentForm pass={pass} />
      </Elements>
    </>
  );
};
