import { useNavigate, useParams } from "react-router-dom";
import { PurchasePassModal } from "./components/PurchasePassModal";
import { useState } from "react";
import { eventShowUrl } from "../../services/routes/urlBuilder";

export const PurchasePassFromEvent: React.FC = () => {
  const [isOpen, setIsOpen] = useState(true);
  const { eventId, passId } = useParams();
  const navigate = useNavigate();

  const handleClose = () => {
    setIsOpen(false);

    if (!eventId) return;
    navigate(
      eventShowUrl(eventId, {
        ticket_selection: true,
      })
    );
  };

  if (!passId || !eventId) return null;

  return (
    <PurchasePassModal
      isOpen={isOpen}
      onClose={handleClose}
      passId={passId}
      eventId={eventId}
    />
  );
};
