import { Control, useController } from "react-hook-form";
import { Panel } from "../../../components/Panel";
import { NumberField } from "../../../components/forms/NumberField";
import { Checkbox, HStack, Text, VStack } from "@chakra-ui/react";
import { useState } from "react";

interface PassConfigurationSectionProps {
  control: Control<any>;
}

export const PassConfigurationSection: React.FC<
  PassConfigurationSectionProps
> = ({ control }) => {
  const {
    field: { value: numCredits, onChange: onChangeNumCredits },
    fieldState: { error: numCreditsError },
  } = useController({
    name: "num_credits",
    control,
    defaultValue: 1,
  });

  const {
    field: { value: numMonthsActive, onChange: onChangeNumMonthsActive },
    fieldState: { error: numMonthsActiveError },
  } = useController({
    name: "num_months_active",
    control,
  });

  const [expires, setExpires] = useState(
    numMonthsActive && numMonthsActive > 0
  );

  const handleChangeExpires = (e: React.ChangeEvent<HTMLInputElement>) => {
    setExpires(e.target.checked);

    if (e.target.checked) {
      onChangeNumMonthsActive(1);
    } else {
      onChangeNumMonthsActive(null);
    }
  };

  return (
    <Panel>
      <VStack alignItems="stretch" gap={4}>
        <Text>Valid for</Text>
        <HStack>
          <NumberField
            value={numCredits}
            onChange={onChangeNumCredits}
            error={numCreditsError?.message}
            min={1}
          />
          <Text>events</Text>
        </HStack>

        <HStack>
          <Checkbox isChecked={expires} onChange={handleChangeExpires} />
          <Text>Expires in</Text>
        </HStack>

        {expires && (
          <HStack>
            <NumberField
              value={numMonthsActive}
              onChange={onChangeNumMonthsActive}
              error={numMonthsActiveError?.message}
              min={1}
            />
            <Text>months</Text>
          </HStack>
        )}
      </VStack>
    </Panel>
  );
};
