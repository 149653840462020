import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { createTicketForPass } from "../../services/api/passes";
import {
  eventShowUrl,
  homeUrl,
  organizationShowUrl,
} from "../../services/routes/urlBuilder";
import { usePass } from "../passes/hooks/usePass";

export const SubscriptionPostPaymentPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { passId } = useParams();
  const { pass } = usePass(passId);
  const navigate = useNavigate();

  const eventIdToEnroll = searchParams.get("eventIdToEnroll");

  useEffect(() => {
    const init = async () => {
      if (!passId || !pass || !navigate) return;

      if (eventIdToEnroll) {
        await createTicketForPass(passId, eventIdToEnroll);
        navigate(eventShowUrl(eventIdToEnroll));
      } else if (pass) {
        navigate(organizationShowUrl(pass.organization_id));
      } else {
        navigate(homeUrl());
      }
    };

    init();
  }, [passId, eventIdToEnroll, pass, navigate]);

  return null;
};
