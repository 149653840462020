import { Heading } from "@chakra-ui/react";
import { TicketTypeForm } from "../events/components/forms/TicketTypeForm";
import { useNavigate, useParams } from "react-router-dom";
import {
  eventTicketsUrl,
} from "../../services/routes/urlBuilder";

export const NewTicketTypePage: React.FC = () => {
  const { eventId } = useParams();

  const navigate = useNavigate();

  const handleSubmit = () => {
    if (!eventId) return;

    navigate(eventTicketsUrl(eventId));
  };

  if (!eventId) return null;

  return (
    <>
      <Heading as="h1" size="lg">
        Add ticket
      </Heading>
      <TicketTypeForm eventId={eventId} onSubmit={handleSubmit} />
    </>
  );
};
