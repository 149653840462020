import { useCallback, useEffect, useState } from "react";
import { Pass } from "../../../types/passes";
import { useCurrentUser } from "../../../providers/CurrentUserProvider";
import { fetchPass } from "../../../services/api/passes";

interface UsePassResult {
  pass: Pass | undefined;
}

export const usePass = (passId: string | undefined): UsePassResult => {
  const [pass, setPass] = useState<Pass | undefined>();
  const { currentUser } = useCurrentUser();

  const fetch = useCallback(async () => {
    if (!currentUser || !passId) return;

    const pass = await fetchPass(passId);
    setPass(pass);
  }, [currentUser, passId]);

  useEffect(() => {
    if (!currentUser) return;
    fetch();
  }, [currentUser, fetch]);

  return { pass };
};
