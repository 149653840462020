import { Heading, HStack, Icon, Link, Text, VStack } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { ButtonLink } from "../../../components/ButtonLink";
import { Panel } from "../../../components/Panel";
import { useCurrentOrganization } from "../../../providers/CurrentOrganizationProvider";
import {
  membershipEditUrl,
  membershipNewUrl,
  passEditUrl,
  passNewUrl,
} from "../../../services/routes/urlBuilder";
import { Pass } from "../../../types/passes";
import { useEffect, useMemo, useState } from "react";
import { fetchPasses } from "../../../services/api/passes";
import { useCurrentUser } from "../../../providers/CurrentUserProvider";
import { TiTicket } from "react-icons/ti";

export const PassesTab: React.FC = () => {
  const { organization } = useCurrentOrganization();
  const { currentUser } = useCurrentUser();
  const [allPasses, setAllPasses] = useState<Pass[]>([]);

  const passes = useMemo(() => {
    return allPasses.filter((pass) => pass.type === "pass");
  }, [allPasses]);

  const memberships = useMemo(() => {
    return allPasses.filter((pass) => pass.type === "membership");
  }, [allPasses]);

  useEffect(() => {
    const fetch = async () => {
      if (!organization || !currentUser) return;

      const passes = await fetchPasses(organization.id);
      setAllPasses(passes);
    };

    fetch();
  }, [organization, currentUser]);

  if (!organization) return null;

  return (
    <>
      <Panel>
        <VStack alignItems="stretch">
          <Heading size="lg">Memberships</Heading>
          {memberships.map((pass) => (
            <HStack key={pass.id}>
              <Icon as={TiTicket} />
              <Link
                as={RouterLink}
                to={membershipEditUrl(organization.id, pass.id)}
              >
                <Text>{pass.name}</Text>
              </Link>
            </HStack>
          ))}

          <ButtonLink
            to={membershipNewUrl(organization.id)}
            buttonProps={{ variant: "primary", size: "lg", width: "100%" }}
          >
            Create membership
          </ButtonLink>

          <Heading size="lg">Passes</Heading>

          {passes.map((pass) => (
            <HStack key={pass.id}>
              <Icon as={TiTicket} />
              <Link as={RouterLink} to={passEditUrl(organization.id, pass.id)}>
                <Text>{pass.name}</Text>
              </Link>
            </HStack>
          ))}

          <ButtonLink
            to={passNewUrl(organization.id)}
            buttonProps={{ variant: "primary", size: "lg", width: "100%" }}
          >
            Create Pass
          </ButtonLink>
        </VStack>
      </Panel>
    </>
  );
};
