import { VianikoEvent } from "../../types/events";
import { RecurringEvent } from "../../types/recurring_events";
import axiosInstance from "./axiosInstance";

export const createRecurringEvent = async (data: VianikoEvent) => {
  const result = await axiosInstance.post(`/recurring_events`, data);
  return result.data;
};

export const fetchRecurringEvents = async (organizationId: string) => {
  const result = await axiosInstance.get(
    `/organizations/${organizationId}/recurring_events`,
  );
  return result.data;
};

export const fetchRecurringEvent = async (id: string) => {
  const result = await axiosInstance.get(`/recurring_events/${id}`);
  return result.data;
};

export const fetchEventsOfRecurringEvent = async (id: string) => {
  const result = await axiosInstance.get(`/recurring_events/${id}/events`);
  return result.data;
};

export const updateRecurringEvent = async (
  id: string,
  data: RecurringEvent,
) => {
  const result = await axiosInstance.patch(`/recurring_events/${id}`, data);
  return result.data;
};

export const deleteRecurringEvent = async (id: string) => {
  await axiosInstance.delete(`/recurring_events/${id}`);
};
