import { Checkbox, HStack, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { RRule } from "rrule";
import { Control, useController } from "react-hook-form";
import { DaysOfWeekCheckbox } from "../../../components/forms/DaysOfWeekCheckbox";
import { NumberField } from "../../../components/forms/NumberField";
import { ControlledNumberField } from "../../../components/forms/ControlledNumberField";
import { StyledDateTimeInput } from "../../../components/forms/StyledDateTimeInput";
import { roundToNearestHours } from "date-fns";

interface FrequencyFormSectionProps {
  control: Control<any>;
  name: string;
  timezone: string;
}

export const FrequencyFormSection: React.FC<FrequencyFormSectionProps> = ({
  control,
  name,
  timezone,
}) => {
  const [frequencyInterval, setFrequencyInterval] = useState(1);
  const [selectedDays, setSelectedDays] = useState<number[]>([]);
  const [hasSeriesEndAt, setHasSeriesEndAt] = useState(false);
  const [seriesEndAt, setSeriesEndAt] = useState<string>();

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: {
      validate: {
        [name]: (value: string) => {
          if (!value) {
            return "This field is required";
          }

          const rrule = RRule.fromString(value);
          if (!rrule.origOptions?.byweekday) {
            return "Please select at least one day of the week";
          } else if (
            !rrule.origOptions?.interval ||
            rrule.origOptions?.interval <= 0
          ) {
            return "Interval must be greater than 0";
          }
        },
      },
    },
  });

  useEffect(() => {
    if (value) {
      const rrule = RRule.fromString(value);
      setFrequencyInterval(rrule.options.interval);
      setSelectedDays(rrule.options.byweekday);
      setSeriesEndAt(rrule.options.until?.toISOString());
    }
  }, [value]);

  const handleChangeDays = (days: number[]) => {
    onChange(
      new RRule({
        freq: RRule.WEEKLY,
        interval: frequencyInterval,
        byweekday: days,
        until: seriesEndAt ? new Date(seriesEndAt) : undefined,
      }).toString()
    );
  };

  const handleChangeInterval = (interval: number) => {
    onChange(
      new RRule({
        freq: RRule.WEEKLY,
        interval,
        byweekday: selectedDays,
        until: seriesEndAt ? new Date(seriesEndAt) : undefined,
      }).toString()
    );
  };

  const handleChangeSeriesEndAt = (date: string) => {
    onChange(
      new RRule({
        freq: RRule.WEEKLY,
        interval: frequencyInterval,
        byweekday: selectedDays,
        until: new Date(date),
      }).toString()
    );
  };

  return (
    <VStack width="100%">
      <HStack width="100%">
        <Text>Repeat every</Text>
        <NumberField
          value={frequencyInterval}
          onChange={handleChangeInterval}
        />
        <Text>{frequencyInterval > 1 ? "weeks" : "week"}</Text>
      </HStack>

      <HStack width="100%">
        <Text marginY={2}>on</Text>
        <DaysOfWeekCheckbox
          defaultValue={selectedDays}
          onChange={handleChangeDays}
        />
      </HStack>

      {error && (
        <Text color="red.500" size="sm" width="100%">
          {error.message}
        </Text>
      )}

      <HStack width="100%">
        <Text>Show on calendar</Text>
        <ControlledNumberField
          name="num_days_scheduled_ahead"
          control={control}
        />
        <Text>days in advance</Text>
      </HStack>

      <VStack width="100%" alignItems="stretch" gap={4}>
        <Text>Stop series</Text>
        <HStack>
          <Checkbox
            id="has_series_end_at"
            isChecked={!hasSeriesEndAt}
            onChange={() => setHasSeriesEndAt(!hasSeriesEndAt)}
          />
          <label htmlFor="has_series_end_at">Never</label>
        </HStack>

        {hasSeriesEndAt && (
          <StyledDateTimeInput
            name="series_end_at"
            control={control}
            defaultValue={roundToNearestHours(new Date()).toISOString()}
            onChange={handleChangeSeriesEndAt}
            timezone={timezone}
            dateOnly
          />
        )}
      </VStack>
    </VStack>
  );
};
