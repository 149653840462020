import { Control, useForm } from "react-hook-form";
import { Pass } from "../../../types/passes";
import { Panel } from "../../../components/Panel";
import { StyledTextarea } from "../../../components/forms/StyledTextarea";
import { StyledControlledInput } from "../../../components/forms/StyledControlledInput";
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Text,
  VStack,
} from "@chakra-ui/react";
import { DollarsInput } from "../../../components/forms/DollarsInput";
import { useParams } from "react-router-dom";
import { useRecurringEvents } from "../../../hooks/useRecurringEvents";

interface PassFormProps {
  onSubmit: (data: Pass) => void;
  defaultValues?: Pass;
  renderSection?: (control: Control<any>) => React.ReactNode;
}

export const PassForm: React.FC<PassFormProps> = ({
  onSubmit,
  defaultValues,
  renderSection,
}) => {
  const { organizationId } = useParams();
  const { recurringEvents } = useRecurringEvents(organizationId);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    watch,
  } = useForm<Pass>({
    mode: "onBlur",
    defaultValues,
  });

  const recurringEventIds = watch("recurring_event_ids");

  const handleChangeValidFor = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setValue("recurring_event_ids", [
        ...(getValues("recurring_event_ids") || []),
        e.target.value,
      ]);
    } else {
      setValue(
        "recurring_event_ids",
        (getValues("recurring_event_ids") || []).filter(
          (id) => id !== e.target.value
        )
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Panel>
        <VStack gap={4}>
          <StyledControlledInput
            name="name"
            control={control}
            label="Name"
            type="text"
            rules={{ required: "This field is required" }}
          />
          <StyledTextarea
            name="description"
            register={register}
            label="Details"
            error={errors.description}
          />
          <DollarsInput name="price_in_cents" label="Price" control={control} />
        </VStack>
      </Panel>

      {renderSection && renderSection(control)}

      <Panel>
        <VStack alignItems="stretch">
          <Text>Valid for</Text>
          <CheckboxGroup defaultValue={recurringEventIds}>
            {recurringEvents?.map((recurringEvent) => (
              <Checkbox
                key={recurringEvent.id}
                value={recurringEvent.id}
                onChange={handleChangeValidFor}
              >
                {recurringEvent.name}
              </Checkbox>
            ))}
          </CheckboxGroup>
        </VStack>
      </Panel>

      <Button
        type="submit"
        variant="primary"
        size="lg"
        width="100%"
        marginTop={6}
        marginBottom={12}
      >
        Save
      </Button>
    </form>
  );
};
