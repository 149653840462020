import { MenuItem, Link, Icon, Text, Portal } from "@chakra-ui/react";
import { BiPencil, BiRepeat, BiTrashAlt } from "react-icons/bi";
import { NavLink, useNavigate } from "react-router-dom";
import {
  eventEditUrl,
  organizationShowUrl,
  recurringEventEditUrl,
} from "../../services/routes/urlBuilder";
import { useState } from "react";
import { deleteEvent } from "../../services/api/events";
import { DeleteConfirmationModal } from "../../components/DeleteConfirmationModal";
import { deleteRecurringEvent } from "../../services/api/recurringEvents";
import { VianikoEvent } from "../../types/events";
import { RecurringEvent } from "../../types/recurring_events";

interface ManageEventMenuListItemsProps {
  event: VianikoEvent & RecurringEvent;
  recurringEventId: string | undefined;
  onDelete: () => void;
}

export const ManageEventMenuListItems: React.FC<
  ManageEventMenuListItemsProps
> = ({ event, recurringEventId, onDelete }) => {
  const [isDeleteEventModalOpen, setIsDeleteEventModalOpen] = useState(false);
  const [isDeleteSeriesModalOpen, setIsDeleteSeriesModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleDeleteEvent = async () => {
    await deleteEvent(event.id);
    setIsDeleteEventModalOpen(false);
    await onDelete();
  };

  const handleDeleteEventSeries = async () => {
    if (!event.recurring_event_id) return;

    await deleteRecurringEvent(event.recurring_event_id);
    if (event.organization_id) {
      navigate(organizationShowUrl(event.organization_id));
    }
  };

  return (
    <>
      <Link as={NavLink} to={eventEditUrl(event.id)}>
        <MenuItem>
          <Icon as={BiPencil} marginRight={2} />
          <Text size="md">
            {recurringEventId ? "Edit this event" : "Edit event"}
          </Text>
        </MenuItem>
      </Link>

      {recurringEventId && (
        <Link as={NavLink} to={recurringEventEditUrl(recurringEventId)}>
          <MenuItem>
            <Icon as={BiRepeat} marginRight={2} />
            <Text size="md">Edit event series</Text>
          </MenuItem>
        </Link>
      )}

      <Link onClick={() => setIsDeleteEventModalOpen(true)}>
        <MenuItem>
          <Icon as={BiTrashAlt} marginRight={2} />
          <Text size="md">
            {recurringEventId ? "Delete this event only" : "Delete event"}
          </Text>
        </MenuItem>
      </Link>

      {recurringEventId && (
        <Link onClick={() => setIsDeleteSeriesModalOpen(true)}>
          <MenuItem>
            <Icon as={BiRepeat} marginRight={2} />
            <Text size="md">Delete event series</Text>
          </MenuItem>
        </Link>
      )}

      <Portal>
        <DeleteConfirmationModal
          isOpen={isDeleteEventModalOpen}
          onClose={() => setIsDeleteEventModalOpen(false)}
          onDelete={handleDeleteEvent}
        />
      </Portal>

      <Portal>
        <DeleteConfirmationModal
          isOpen={isDeleteSeriesModalOpen}
          onClose={() => setIsDeleteSeriesModalOpen(false)}
          onDelete={handleDeleteEventSeries}
          bodyText="This will delete all future events in the series"
        />
      </Portal>
    </>
  );
};
